import {createRouter, createWebHistory} from 'vue-router'

import Home from '../views/HomeView.vue'


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/en',
        name: 'HomeEn',
        component: () => import('../views/EnglView.vue')
    },
    {
        path: '/esp',
        name: 'HomeEsp',
        component: () => import('../views/EspView.vue')
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})


export default router
