<template>
  <NavBar></NavBar>
    
  <router-view class="h-screen" :style="{ 'max-height': containerHeight + 'px' }"></router-view>

  
  
</template>

<script>
import NavBar from '../src/components/NavBar.vue'
export default {
  name: 'App',
  components: {
    NavBar

  },
  data () {
    return {


    }
  },

  mounted(){

    this.setContainerHeight();
    window.addEventListener('resize', this.setContainerHeight);


  },





  methods: {

    
    setContainerHeight() {
      // Calculer la hauteur disponible du viewport
      const viewportHeight = window.innerHeight;

      // Calculer la hauteur que vous souhaitez appliquer, par exemple 93% de la hauteur du viewport
      this.containerHeight = viewportHeight;
      console.log('containerHeight', this.containerHeight);
      

    },
    
    scrollToTop() {
      // Scroll the page to the top
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can change this to 'auto' for immediate scrolling
      });
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      if (scrollTop > this.lastScrollTop) {
        this.deployed = false;
      } 
      this.lastScrollTop = scrollTop;
    },

    scrollToSection(section) {
      // Scroll the page to the top
      window.scrollTo({
        top: document.getElementById(section).offsetTop,
        behavior: 'smooth', // You can change this to 'auto' for immediate scrolling
      });
    },
  },
};



</script>

<style>

body{
  height: 150vh;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 0px; /* largeur de la barre de défilement */
}

::-webkit-scrollbar-thumb {
  background-color: #2c2f64;
  border-radius: 5px;
  height: 8px; /* largeCouleur de la barre de défilement */
}

::-webkit-scrollbar-track {
  background-color: #4f505f; /* Couleur de fond de la piste de défilement */
}
 
html {
  scroll-behavior: smooth;
}


@font-face {
  font-family: "RamdoneScript"; 
  src: url("../src/assets/font/Ramdone.ttf") format("truetype"); 
 
}

@font-face {
  font-family: "Poppins"; 
  src: url("../src/assets/font/Poppins-Regular.ttf") format("truetype"); 
 
}

.popreg{
  font-family: "Poppins"; 
}


@font-face {
  font-family: "PoppinsBold"; 
  src: url("../src/assets/font/Poppins-Bold.ttf") format("truetype"); 
 
}

.popbold{
  font-family: "PoppinsBold";
}

@font-face {
  font-family: "PoppinsBoldItalic"; 
  src: url("../src/assets/font/Poppins-BoldItalic.ttf") format("truetype"); 
 
}
.popboldita{
  font-family: "PoppinsBoldItalic";
}

@font-face {
  font-family: "PoppinsMedium"; 
  src: url("../src/assets/font/Poppins-Medium.ttf") format("truetype"); 
 
}

.popmed{
  font-family: "PoppinsMedium";
}

@font-face {
  font-family: 'PoppinsItalic';
  src: url("../src/assets/font/Poppins-Italic.ttf") format("truetype");
}

.popita{
  font-family: 'PoppinsItalic';
}


.grad1{
  background: linear-gradient(to right, #3385FF , #A1D2FF); /* Dégradé en couleur de la bordure */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: #3c90ffa8 0px 0px 25px;
}

.grad2{
  background: linear-gradient(to right, #31479D, #52A3FF ); /* Dégradé en couleur de la bordure */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



</style>
