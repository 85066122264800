<template>
  <div class="flex align-center justfiy-around h-16 lg:h-20 fixed top-0 z-40 transition-all bgnav pl-6 lg:pl-12 w-full pr-12" :class="{'deployed' : !deployed}" @mouseenter="this.deployed= true">
   
    <h1 @click="scrollToTop" class="text-2xl lg:text-4xl refreshTxt flex flex-col justify-around pr-12 lg:pl-8 cursor-pointer">Refresh</h1>

    <div class="hidden lg:flex  w-1/2 mr-1/4">
      <h2 class="titre-section mx-12" @click="this.$parent.scrollToSection('services')">Nos Services</h2>
      <h2 class="titre-section" @click="this.$parent.scrollToSection('about')">A propos</h2>

    </div>

    <div class="lg:w-1/3 pl-12 flex justify-between h-full ml-1/3 absolute right-0 hidden lg:flex" >
      <div class="flex w-full mr-6">
        <ChangeLang class="w-1/2 mr-6" :class="{'opacity-0' : !deployed}"></ChangeLang>
        <button @click="this.$parent.scrollToSection('contact')" class="btn-contact h-1/2"><p class="txtbtn">Contactez-nous</p></button>
     
        

      </div>
      
    </div>

    <div class="lg:hidden w-full h-8 absolute right-0 flex flex-col justify-around h-full mr-6 cursor-pointer py-4 svg-container select-none">
      <div class="relative mt-2 flex justify-end select-none" >
        <ChangeLang class="w-1/2 mr-6 absolute right-8 mt-3 opacity-0" style="transition: 0.5s ease" :class="{'opacity-100' : menuOpened}"></ChangeLang>
        <img @click="menuOpened = !menuOpened" class="h-6 w-6 select-none absolute transition-all" :class="{'opacity-0 transform -rotate-45':menuOpened}" src="../assets/nav/burger.svg" alt="">
        <img @click="menuOpened = !menuOpened" class="h-6 w-6 text-white absolute transition-all transform rotate-45 opacity-0" :class="{'rotate-0 opacity-100':menuOpened}" src="../assets/nav/cross.svg" alt="">
      </div>    
    </div>

    

    

    
  </div>
  <div class="fixed blur-bg z-20 top-0 w-full menu-mob overflow-hidden h-full transition-all" :class="{'h-[120vh]' : menuOpened, 'h-0 opacity-0' : !menuOpened}" >
    <div class=" transition-all lg:hidden overflow-hidden px-6 pt-20 fixed top-0 right-0 flex flex-col z-20 menu-transition justify-around cursor-pointer w-full bg-white" :class="{ 'h-1/2 opacity-100': menuOpened, 'h-0 opacity-0': !menuOpened }" @transitionend="handleTransitionEnd">
      
      <h2 class="w-full text-center text-white my-2" @click="this.$parent.scrollToSection('services'); menuOpened=!menuOpened">Nos Services</h2>
      <h2 class="w-full text-center text-white my-2" @click="this.$parent.scrollToSection('about'); menuOpened=!menuOpened">A propos</h2>
      <button class="btn-contact-tel mb-12 mt-4" @click="this.$parent.scrollToSection('contact'); menuOpened=!menuOpened"><p class="">Contactez-nous</p></button>

    </div>
    <div @click="menuOpened = false" class="h-full transition-all lg:hidden absolute top-1/2 w-full overflow-hidden"></div>
  </div>
  
 
</template>

<script>
import ChangeLang from '../components/ChangeLang.vue'
export default {
  name: 'NavBar',
  props: {},
  components: {
    ChangeLang
  },
  data() {
    return {
      deployed: true,
      lastScrollTop: 0,
      menuOpened: false
    };
  },
  computed: {
 
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToTop() {
      // Scroll the page to the top
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can change this to 'auto' for immediate scrolling
      });
    },
    handleScroll() {
      const scrollTop = window.scrollY;

      if (scrollTop > this.lastScrollTop) {
        this.deployed = false;
      } 

      //scroll top = deployement 

      /*else if (scrollTop < this.lastScrollTop) {
        this.deployed = true;
      }*/

      //-----

      this.lastScrollTop = scrollTop;
    },
  },
};
</script>

<style scoped>

.deployed {
  transition: 0.2s;
  height: 50px;
}

.deployed > .refreshTxt{
  transition: 0.2s;
  font-size: 1.5rem;
}
.deployed div > .titre-section{
  transition: 0.2s;
  font-size: 0.8rem;
  margin-left: 2vw;
}

.deployed div > .btn-contact{
  transition: 0.2s;
  font-size: 0.7rem;
  width: 1/3;
  color: white;
  width: 33%;
  margin-left: 15%;
  border-radius: 5px;
  font-family: Poppins;
  width: 150px;
  min-width: 150px;
}

.bgnav{
  background-color: #0c133add ;
  backdrop-filter: blur(20px);
}

.refreshTxt{
  transition: 0.2s;
  font-family: "RamdoneScript";
  background: linear-gradient(to right, #3385FF , #A1D2FF); /* Dégradé en couleur de la bordure */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: #3c90ffa8 0px 0px 25px;
}

.refreshTxt:hover{
  transition: 0.2s;
  transform: scale(1.05);
}

.titre-section{
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  color: white;
  cursor: pointer;
  font-size: 1rem;

}

.titre-section:hover{
  color: #C0E3EB;
  transition: 0.2s;
}


.btn-contact{
  
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PoppinsBold";
  background: linear-gradient(160deg, #31479D  , #52A3FF); /* Dégradé en couleur de la bordure */
  border-radius: 10px;
  padding: 5px 25px;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 200px;
}



.btn-contact:hover {
  transform: scale(1.02);
  transition: 0.1s;
}

.btn-contact-tel{
  
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PoppinsBold";
  background: linear-gradient(160deg, #31479D  , #52A3FF); /* Dégradé en couleur de la bordure */
  border-radius: 10px;
  padding: 5px 25px;
  min-width: 200px;
}


.blur-bg{
  background-color: #172352c2;
}

.menu-transition{
  transition-duration: 0.3s;   
  transition-timing-function: ease-in-out; 
  background-color: #0d173ff5;
}

.burger{
  animation: burgeranim ease-in-out 0.5;
}

@keyframes burgeranim{
  0%{
    transform: rotate(0);
    opacity: 100;
  }

  50%{
    transform: rotate(45deg);
    opacity: 100;
  }
  100%{
    opacity: 0;
  }
}


.svg-container{
  touch-action: none;

}


@media (max-width: 1030px) {
  .deployed{
    height: 60px;
  }
}


</style>
