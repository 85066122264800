<template>
  <div class="h-full flex flex-col justify-around">
    <div class="h-8 relative flex flex-col justify-around" @mouseleave="hovered = false">
      <img @click="setLang(selection[2])" :src="require(`../assets/lang/${selection[2]}.svg`)" class="cursor-pointer aspect-auto h-6 absolute right-0 flag opacity-0" :class="{'flag-3 opacity-100': hovered}" alt="">
      <img @click="setLang(selection[1])" :src="require(`../assets/lang/${selection[1]}.svg`)" class="cursor-pointer aspect-auto h-6 absolute right-0 flag opacity-0" :class="{'flag-2 opacity-100': hovered}" alt="">
      <img :src="require(`../assets/lang/${selection[0]}.svg`)" class="cursor-pointer aspect-auto h-6 absolute right-0 flag flag-1 pop" @mouseenter="hovered = true" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeLang',
  data() {
    return {  
      hovered: false,
      selection: ["fr", "esp", "eng"]
    };
  },
  methods: {
    setLang(lang) {
      if (lang === this.selection[0]) {
        return;
      }

      let i = this.selection.indexOf(lang);
      let old = this.selection[0];
      this.selection[i] = old;
      this.selection[0] = lang;
      this.hovered = false;

      switch (lang) {
        case "fr":
          this.$router.push("/");
          break;
        case "esp":
          this.$router.push("/esp");
          break;
        case "eng":
          this.$router.push("/en");
          break;
        default:
          break;
      }              
    }
  }
};
</script>

<style scoped>
.flag-2 {
  transform: translateX(-200%);
}

.flag-3 {
  transform: translateX(-400%);
}

.flag {
  transition: 0.4s;
}

.flag-1:hover {
  animation: pop 0.25s ease-in-out;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
