<template>
  
    <section id="landing" class="w-full paddintop landing sm:landing-tel px-2 md:px-0">
      <div class="spawn1">
        <h2 class="w-full text-center md:text-xl text-white popboldita spawn1">Développement</h2>
        <h2 class="w-full text-center sm:text-xl text-white popita spawn2">de votre activité en ligne</h2>

        <h1 class="w-full my-4 px-4 sm:px-0 sm:my-8 py-2 text-center text-3xl md:text-5xl text-white popbold grad1  maintitle">Laissez place à notre imagination.</h1>
        
        <h3 class="w-full text-center px-4 md:px-0 md:text-xl text-white popreg spawn3 popita"><strong class="popboldita">Ensemble</strong>, donnons vie à votre signature digitale.</h3>
        
        <div class="w-full flex justify-around mt-16 spawn4">
          <button class="popboldita text-white discover-btn h-12" @click="this.$parent.$parent.scrollToSection('services')">Découvrir</button>
        </div>
      </div>

    </section>

    <section id="services" class="w-full services px-2 lg:px-0 pt-12 text-2xl mx-auto">

      <h2 class="grad2 popbold w-full text-center text-4xl lg:text-2xl">Nos Services</h2>
      <h3 class="popmedium w-full text-center mt-2 lg:mt-4 text-sm lg:text-xl">A chacun ses besoins.</h3>
      <h3 class="popmedium w-full text-center text-xs lg:text-xl">Voyez parmi nos services celui qui vous convient.</h3>

      <div class="fadeInDiv flex flex-col lg:flex-row flex-col-reverse justify-between mt-8 lg:px-32 lg:mt-24 lg:mx-16">
        <div class="w-full lg:w-1/2 px-4">
          <h4 class="grad2 popbold w-full text-center lg:text-right text-xl md:text-2xl lg:text-4xl">Site Internet Vitrine</h4>
          <p class="text-center lg:text-right text-xs md:text-xl lg:text-xl mt-2 lg:mt-6">Dynamisez votre présence en ligne avec un site vitrine professionnel qui met en valeur votre entreprise. Attirez de nouveaux clients et communiquez efficacement sur vos produits et services grâce à une plateforme web attrayante et facilement accessible.</p>
        </div>
        <div class="w-full lg:w-1/2 flex justify-around">
          <img class="h-32 m-4 lg:m-0 md:h-5/6 back-light" src="../assets/services-section/tablet_ic.png" alt="">
        </div>
      </div>

      <div class="fadeInDiv flex flex-col lg:flex-row justify-between mt-8 lg:px-32 lg:mt-24 lg:mx-16">
        <div class="w-full lg:w-1/3 flex justify-around">
          <img class="back-light h-40 md:h-full mb-6 lg:mb-0" src="../assets/services-section/phone_ic.png" alt="">
        </div>

        <div class="w-full lg:w-2/3 px-4">
          <h4 class="grad2 popbold  w-full text-center lg:text-left text-xl md:text-2xl lg:text-4xl">Site Internet dynamique</h4>
          <p class="text-center lg:pr-12 lg:text-left text-xs md:text-xl lg:text-xl mt-2 lg:mt-6">Atteignez votre public cible où qu'il soit grâce à une application mobile innovante et intuitive. Proposez une expérience utilisateur immersive, fidélisez vos utilisateurs et augmentez votre visibilité grâce à une présence stratégique sur les plateformes mobiles les plus populaires.</p>
        </div>
        
      </div>

      <div class="fadeInDiv flex flex-col lg:flex-row flex-col-reverse justify-between mt-8 xl:px-32 lg:mt-24 lg:mx-16">
        <div class="w-full lg:w-1/2 px-4">
          <h4 class="grad2 popbold w-full text-center lg:text-right text-xl md:text-2xl lg:text-4xl">Application Web</h4>
          <p class="text-center lg:text-right text-xs md:text-xl lg:text-xl mt-2 lg:mt-6">Optimisez vos processus métier et facilitez la collaboration avec une application web personnalisée. Gagnez en productivité et en efficacité grâce à une solution sur mesure qui répond parfaitement à vos besoins spécifiques.</p>
        </div>
        <div class="w-full lg:w-1/2 flex justify-around">
          <img class="h-32 m-4 lg:m-0 md:h-5/6 back-light" src="../assets/services-section/pc_ic.png" alt="">

        </div>
      </div>

      <div class="fadeInDiv w-full flex justify-around mt-24">
          <button class="popboldita text-sm lg:text-xl text-white devis-btn h-12 mx-12 lg:mx-0" @click="this.$parent.$parent.scrollToSection('contact')">Demander un devis</button>
        </div>

    </section>

    <section id="about" class="infos pb-20 px-0  pt-12 text-2xl w-full overflow-hidden lg:px-40">
      <div class="px-10 lg:px-0 lg:flex justify-between mt-40 fadeInDiv">
        <div class="flex lg:flex-col justify-around h-full lg:w-1/2">
          <img class="w-full mb-10 lg:mb-0 lg:w-3/4 max-w-[600px]" src="../assets/infos/img1.png" alt="">
        </div>
        <div class="lg:w-1/2">
          <h1 class="grad1 popbold text-xl md:text-2xl">A propos de notre agence web</h1>
          <h2 class="text-white popbold text-2xl md:text-4xl my-5">Une agence web innovante et polyvalente</h2>
          <p class="text-white text-sm">Refresh est une agence web basée en France à Paris, et spécialisée dans la digitalisation d’entreprise de par la création de site vitrine, d’application web et de site e-commerce, développé sur mesure rien que pour vous.<br/>Nous offrons également des services de marketing digital, y compris le référencement gratuit (SEO) et le référencement payant (Google Ads), afin d’aider les entreprises à atteindre leur public cible et à augmenter leur visibilité en ligne.<br/><br/>Digitaliser son entreprise est devenu une nécessité à l’heure d’aujourd’hui si vous souhaitez passer à la vitesse supérieure et rendre votre entreprise plus rentable dans ses objectifs. Nous avons tout ce qu’il vous faut pour vous permettre d’y parvenir.</p>
        </div>
      </div>
      
      <div class="px-10 lg:px-0 lg:flex justify-between mt-40 fadeInDiv">
        <div class="flex lg:flex-col justify-around h-full lg:hidden">
          <img class="w-full mb-10 lg:mb-0 lg:w-3/4 max-w-[600px]" src="../assets/infos/img2.png" alt="">
        </div>
        <div class="w-full lg:w-1/2">
          <h1 class="grad1 popbold text-xl md:text-2xl">Nos activités d’agence web française</h1>
          <h2 class="text-white popbold text-2xl md:text-4xl my-5">Votre agence web disponible dans toute la France</h2>
          <p class="text-white text-sm">Notre équipe de professionnels travaille en étroite collaboration avec vous pour comprendre les besoins de votre entreprise et créer des solutions adaptées à vos besoins. Nous offrons des services de référencement SEO pour vous aider à augmenter votre visibilité en ligne et à attirer de nouveaux clients. Nous créons également des sites web sur mesure qui sont à la fois esthétiques et fonctionnels pour répondre à vos besoins spécifiques.<br/><br/>Si vous cherchez à créer une boutique en ligne, notre équipe peut également vous aider à créer une solution e-commerce sur mesure qui répond à vos besoins uniques. Nous travaillons avec les principales plateformes de commerce électronique pour créer des sites web professionnels qui sont faciles à naviguer et à utiliser pour vos clients.<br/><br/>Chez Referesh, nous sommes déterminés à fournir des solutions de qualité pour améliorer votre présence en ligne. Contactez-nous dès aujourd'hui pour discuter de vos besoins et en savoir plus sur la façon dont nous pouvons vous aider à atteindre vos objectifs.</p>
        </div>
        <div class="flex lg:flex-col justify-around h-full lg:w-1/2">
          <img class="ml-20 lg:mt-16 hidden lg:block max-w-[600px]" src="../assets/infos/img2.png" alt="">
          <div class="flex justify-around lg:mx-20 mt-10  lg:mt-10 w-full text-center">
            <div class="w-20">
              <h3 class="grad1 popbold">+5</h3>
              <h4 class="text-xs text-white">Années d'experience</h4>
            </div>
            <div class="w-20">
              <h3 class="grad1 popbold">+20</h3>
              <h4 class="text-xs text-white">Projets Achevés</h4>
            </div>
            <div class="w-20">
              <h3 class="grad1 popbold">+10</h3>
              <h4 class="text-xs text-white">Années d'experience</h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" class="contact pb-20 px-0 pt-12 text-2xl w-full overflow-hidden">
      <h2 class="fadeInDiv grad2 popbold w-full text-center text-2xl text-2xl">Contact</h2>
      <h3 class=" fadeInDiv px-8 md:px-0 popmedium w-full text-center mt-2 lg:mt-4 text-sm lg:text-xl">Un commentaire ? Une question ? Faites-nous en part.</h3>
      <div class=" fadeInDiv flex justify-around mt-8 md:mt-12">
        <div class="floublue h-30 w-11/12 md:w-10/12 lg:w-8/12 rounded-xl md:flex p-8">
          <div class="w-full md:w-1/2 md:pr-12 md:border-r border-solid border-blue-300 ">
            <h2 class="grad2 popbold w-full text-2xl md:text-3xl">Contactez-nous</h2>
            <p class="text-xs md:text-sm mt-4 text-justify">
              Pour toute question, demande de conseils, remarque, nous vous prions de remplir ce formulaire. Ce dernier ne constitue pas une demande de devis.
            </p>
            <div class="flex w-full mt-8 md:mt-12">
              <div class="w-1/6">
                <img src="../assets/contact/icphone.svg" class="h-full" alt="">
              </div>
              <h4 class="w-5/6 text-xs mt-1 md:mt-0 md:text-sm popreg">+33 6 12 34 56 78</h4>
            </div>
            <div class="flex w-full mt-4 md:mt-6">
              <div class="w-1/6">
                <img src="../assets/contact/icmail.svg" class="h-full" alt="">
              </div>
              <h4 class="w-5/6 text-xs mt-1 md:mt-0 md:text-sm popreg">contact@refreshapp.net</h4>
            </div>
            <div class="flex w-full mt-4 md:mt-6">
              <div class="w-1/6">
                <img src="../assets/contact/icinsta.svg" class="h-full" alt="">
              </div>
              <h4 class="w-5/6 text-xs mt-1 md:mt-0 md:text-sm popreg">@refresh-app</h4>
            </div>
            <div class="mt-6">
              <div class="h-captcha hidden md:block" data-sitekey="4c1379f8-01b8-4a1c-9037-ec035ec8b12b" data-callback="onCaptchaComplete"></div>
            </div>
          </div>
          <div class="w-full md:w-1/2 md:pl-4">
            <div action="" class="mt-2">
              <div class="flex justify-between relative">
                <div class="px-2">
                  <label class="text-xs md:text-sm" for="firstname">Prénom *</label>
                  <input v-model="firstName" id="firstname" class="text-xs md:text-sm outline-none w-full rounded-xl bg-white px-1 h-8" type="text" style="border: 1px #00409f7a solid;">
                </div>
                <div class="px-2">
                  <label class="text-xs md:text-sm" for="lastname">Nom *</label>
                  <input v-model="lastName" id="lastname" class="text-xs md:text-sm outline-none w-full rounded-xl bg-white px-1 h-8" type="text" style="border: 1px #00409f7a solid;">
                </div>
              </div>
              <div class="px-2">
                <label class="text-xs md:text-sm" for="mail">Email *</label>
                <input v-model="email" id="mail" class="text-xs md:text-sm outline-none w-full rounded-xl bg-white px-1 h-8" type="text" style="border: 1px #00409f7a solid;">
              </div>
              <div class="px-2">
                <label class="text-xs md:text-sm" for="mail">Description - Message *</label>
                <textarea v-model="content" id="msg" class="outline-none text-sm resize-none w-full rounded-lg p-1 bg-white h-28" style="border: 1px #00409f7a solid;"></textarea>
              </div>
              <div class="w-3/4 md:w-1/2 text-center text-sm pt-1 ml-2">
                  <div class="h-captcha md:hidden" data-sitekey="4c1379f8-01b8-4a1c-9037-ec035ec8b12b" data-callback="onCaptchaComplete"></div>
                </div>
              <div class="flex md:px-2 pt-2">
                
                <button v-if="!messageSent" class="popboldita text-sm lg:text-xl text-white send-btn h-12 lg:mx-0 md:mt-2 w-full" v-on:click="this.sendMessage(this.firstName, this.lastName, this.email, this.content)">Envoyer</button>
                <div v-else class="popboldita text-sm lg:text-xl text-white send-btn h-12 lg:mx-0 md:mt-2 w-full">Le message a été envoyé !</div>
                
              </div>
              <h2 id="errormsg" class="w-full popreg text-red-400 text-center text-sm absolute bottom-2 left-1/4 transition-all" :class="{'opacity-0' : !error}">Veuillez remplir tous les champs</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="footer" class="relative footer h-[30vh] md:h-[50vh] pb-20 px-2 md:px-12 pt-4 md:pt-20 text-2xl w-full overflow-hidden md:flex">
      <div class="px-4 md:px-0 w-full md:w-1/5 text-center flex md:block border-b md:border-none border-solid border-white">
        <h1 class="text-3xl lg:text-[5rem] text-white pb-2 md:pb-8 md:border-b border-solid border-white" style="font-family: 'RamdoneScript';">Refresh</h1>
        <div class="flex justify-around mt-2 md:mt-4 w-full ml-6 md:ml-0">
          <img src="../assets/footer/icgithub.svg" class="h-4 md:h-full " alt="">
          <img src="../assets/footer/icx.svg" class="h-4 md:h-full" alt="">
          <img src="../assets/footer/icinsta.svg" class="h-4 md:h-full" alt="">
          <img src="../assets/footer/icfacebook.svg" class="h-4 md:h-full" alt="">
        </div>
      </div>
      <div class="w-full mt-2 md:mt-0 md:w-4/5 md:px-12 flex justify-around popreg text-xs md:text-sm text-white">
        <div>
          <h3 class="popbold">Services</h3>
          <h4 class="text-[8px] md:text-xs  my-1 md:my-4">Web Design</h4>
          <h4 class="text-[8px] md:text-xs my-1 md:my-4">Marketing Digital</h4>
          <h4 class="text-[8px] md:text-xs my-1 md:my-4">Développement sur mesure</h4>
          <h4 class="text-[8px] md:text-xs my-1 md:my-4">Référencement SEO</h4>
          <h4 class="text-[8px] md:text-xs my-1 md:my-4">E-Commerce</h4>
        </div>
        <div>
          <h3 class="popbold">A propos</h3>
          <h4 class="text-[8px] md:text-xs my-1 md:my-4">Mentions Légales</h4>
          <h4 class="text-[8px] md:text-xs my-1 md:my-4">Conditions Générales de vente</h4>
        </div>
        <div>
          <h3 class="popbold">Contact</h3>
          <h4 class="text-[8px] md:text-xs my-1 md:my-4">Instagram: @refreshappnet</h4>
        </div>

      </div>
      <h4 class="absolute bottom-2 md:bottom-4 w-full text-center text-gray-100 popreg text-[10px] md:text-xs">Ⓒ 2024 Refresh, Inc. Tous droits réservés</h4>
    </section>

</template>
<script>



import ScrollReveal from 'scrollreveal';
const axios = require('axios');

export default {
    components: {
      
  },
    data() {
      return {
        taroHovered: 2,
        isPcFormat: window.innerWidth >= 1024, // Exemple : 1024 est la largeur d'un écran PC
        messageSent: false,
        error: false
      };
    },
    
    
    methods: {

     

      onCaptchaComplete(response) {
        this.hCaptchaResponse = response;
      },

        sendMessage(firstname, lastname, email, content){
          if(!firstname || !lastname || !email || !content || !this.hCaptchaResponse){
            if(!firstname){
              document.getElementById("firstname").style.border = "1px solid red";
              this.error = true;
            }
            if(!lastname){
              document.getElementById("lastname").style.border = "1px solid red";
              this.error = true;
            }
            if(!email){
              document.getElementById("mail").style.border = "1px solid red";
              this.error = true;
            }
            if(!content){
              document.getElementById("msg").style.border = "1px solid red";
              this.error = true;
            }
 
            return
          }
          this.error = false
          const postData = {
            firstName: firstname,
            lastName: lastname,
            email: email,
            content: content,
            hcaptchaResponse: this.hCaptchaResponse
          };


          axios.post('https://api.refreshapp.net/api/postMessage', postData)
          .then(response => {
            console.log('Server response:', response.data);
            this.firstName = ""
            this.lastName = ""
            this.email = ""
            this.content = ""
            this.messageSent = true
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }
      },


      created() {
        window.onCaptchaComplete = this.onCaptchaComplete;
      },

      mounted() {
        const element = this.$refs.swipref
        console.log(element)


        const sr = ScrollReveal();
    
        // Configurez les options d'animation
        sr.reveal('.fadeInDiv', {
          delay: 100,
          distance: '20px',
          origin: 'bottom',
          duration: 700,
          easing: 'cubic-bezier(0.5, 0, 0, 1)',
        });

        

  
},

  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Hide scrollbar for Firefox */
.popboldita{
  font-family: 'PoppinsBoldItalic';
}

.popita{
  font-family: 'PoppinsItalic';
}

.text-pt{
  font-size: 12px;
}

.maintitle{
  animation: animtitle 2.5s ease-in-out infinite;
}

.popreg{
  font-family: 'Poppins';
}

.popmedium{
  font-family: 'PoppinsMedium';
}

.back-light{
  filter: drop-shadow(#3c90ffa8 0px 0px 30px);
}

.flame-light{
  filter: drop-shadow(#ffa13cd5 0px 0px 30px);

}

.discover-btn{
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PoppinsBold";
  background: linear-gradient(160deg, #31479D  , #52A3FF); /* Dégradé en couleur de la bordure */
  border-radius: 10px;
  padding: 10px 50px;
  margin-top: auto;
  margin-bottom: 28vh;
}

.discover-btn:hover{
  transition: 0.2s ease;
  transform: scale(1.02);
}

.devis-btn{
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PoppinsBold";
  background: linear-gradient(160deg, #31479D  , #52A3FF); /* Dégradé en couleur de la bordure */
  border-radius: 10px;
  padding: 10px 50px;
  margin-bottom: 18vh;
  cursor: pointer;
}

.devis-btn:hover{
  transition: 0.2s ease;
  transform: scale(1.02);
}

.send-btn{
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PoppinsBold";
  background: linear-gradient(160deg, #31479D  , #52A3FF); /* Dégradé en couleur de la bordure */
  border-radius: 10px;
  padding: 0px 50px;
  cursor: pointer;
  width: 100%;
}
.send-btn:hover{
  transition: 0.2s ease;
  transform: scale(1.02);
}

.spawn1{
  animation: spawn1 1s;
}

.spawn2{
  animation: spawn2 1s;
}

.spawn3{
  animation: spawn3 1s;
}

.spawn4{
  animation: spawn4 1s;
}


.landing{
  background-image: none;
  background-image: url('../assets/landing/bg_pc.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
}


.popbold{
  font-family: 'PoppinsBold';
}

.paddintop{
  padding-top: 30vh;
}

/* ---------------- */

.services{
  background-image: url('../assets/services-section/bg_pc.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow-y: hidden;
}


.offres{
  background-image: url('../assets/offres/bg_offres.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow-y: hidden;
}



@media (max-width: 500px) {
  .landing{
    background-image: none;
    background-image: url('../assets/landing/bg_tel.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    overflow-y: hidden;
  }

  .paddintop{
    padding-top: 25vh;
  }

}





@keyframes animtitle{
  0%{
    transform: scale(1.0);
  }

 

  50%{
    transform: scale(1.02);
  }

  100%{
    transform: scale(1.0);
  }
  
}

@keyframes spawn1{
  0%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

@keyframes spawn2{
  0%{
    opacity: 0;
  }

  30%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

@keyframes spawn3{
  0%{
    opacity: 0;
  }

  60%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

@keyframes spawn4{
  0%{
    opacity: 0;
  }

  80%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}


/* --- TARO --- */

.border-color{
    
    border-radius: 10px; 
    background-image: linear-gradient(180deg, #3385FF, #A1D2FF); /* Dégradé en couleur de la bordure */
    border-image-slice: 1;
    transform: scale(1.045, 1.03);
    transition: 0.3s;
    
  }

  .background-card{
    border-radius: 10px; 

  }

  .card-tarohover{
    transform: scale(1.05);
    transition: 0.2s;
  }

  .card-taro{
    transition: 0.2s;
  }

 

  
  .card-taro:hover .border-color{
    animation: bounceBorder 0.3s ease-in-out;
    
  }

  @keyframes bounceBorder {
    0% {
      transform: scale(1.045, 1.03);

      

    }
    50% {
      transform: scale(1.065, 1.04);
      

    }
    100% {
      transform: scale(1.045, 1.03);
    }
  }

  .taroTitle{
    font-family: 'PoppinsBold';
  }

  .starter{
    background-color: #7EB9FF;
  }

  .unlimited{
    background-color: #3671C8;
  }

  .proplan {
    background-color: #273C87;
  }

  .scalechange{
    transform: scale(1.2)
  }

  

  .contact-btn-offre{
    background-color: white;
    color: white;
    font-family: 'PoppinsBold';
    padding: 4px 15px;
    position: relative;
    z-index: 10;

  }

  .contact-btn-offre::before{
  content: '';
  position: absolute;
  top: -10%; 
  left: -10%; 
  right: +10%;
  bottom: +10%;
  background-image: linear-gradient(90deg, #007BFF, #00BFFF);
  border-radius: 15px;
  height: 120%;
  width: 120%;
  z-index: -3;
}
  

  
  .slidewidth{
    width: 580px;
    min-width: 580px;
  }

  .bg-tabl{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    overflow-y: hidden;
  }

  .flouwhite{
    background-color: #f1f7fce3;
    backdrop-filter: blur(5px); /* Adjust the blur radius as needed */

  }

  .floublue{
    background-color: #c2def462;
    backdrop-filter: blur(5px); /* Adjust the blur radius as needed */

  }

  .infos{
    background-image: url('../assets/infos/bg_infos.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    overflow-y: hidden;
  }


  .contact{

    background-image: url('../assets/contact/bg_contact.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    overflow-y: hidden;
}

.footer{
  background-image: url('../assets/footer/bg_footer.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    overflow-y: hidden;
}


</style>

